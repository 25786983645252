import { Icon } from "@chakra-ui/react";

export const MyQueueSVGIcon = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 3.5C3.5 4.34375 2.8125 5 2 5C1.15625 5 0.5 4.34375 0.5 3.5C0.5 2.6875 1.15625 2 2 2C2.8125 2 3.5 2.6875 3.5 3.5ZM0.5 8.5C0.5 7.6875 1.15625 7 2 7C2.8125 7 3.5 7.6875 3.5 8.5C3.5 9.34375 2.8125 10 2 10C1.15625 10 0.5 9.34375 0.5 8.5ZM0.5 13.5C0.5 12.6875 1.15625 12 2 12C2.8125 12 3.5 12.6875 3.5 13.5C3.5 14.3438 2.8125 15 2 15C1.15625 15 0.5 14.3438 0.5 13.5ZM6.5625 2H13.25C13.6562 2 14 2.34375 14 2.75V4.25C14 4.6875 13.6562 5 13.25 5H6.5625C6.34375 5 6.15625 4.9375 5.96875 4.8125L4.78125 3.90625C4.5 3.71875 4.5 3.3125 4.78125 3.125L5.96875 2.21875C6.15625 2.09375 6.34375 2 6.5625 2ZM4.78125 8.125L5.96875 7.21875C6.15625 7.09375 6.34375 7 6.5625 7H15.25C15.6562 7 16 7.34375 16 7.75V9.25C16 9.6875 15.6562 10 15.25 10H6.5625C6.34375 10 6.15625 9.9375 5.96875 9.8125L4.78125 8.90625C4.5 8.71875 4.5 8.3125 4.78125 8.125ZM4.78125 13.125L5.96875 12.2188C6.15625 12.0938 6.34375 12 6.5625 12H13.25C13.6562 12 14 12.3438 14 12.75V14.25C14 14.6875 13.6562 15 13.25 15H6.5625C6.34375 15 6.15625 14.9375 5.96875 14.8125L4.78125 13.9062C4.5 13.7188 4.5 13.3125 4.78125 13.125Z"
      fill="currentColor"
    />
  </svg>
);
