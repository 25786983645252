import { Icon } from "@chakra-ui/react";

export const IndustryNewsSVGIcon = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 1.5H4.75C3.78125 1.5 3 2.3125 3 3.25V13.25C3 13.6875 2.65625 14 2.25 14C1.8125 14 1.5 13.6875 1.5 13.25V4C1.5 3.59375 1.15625 3.25 0.75 3.25C0.3125 3.25 0 3.59375 0 4V13.25C0 14.5 1 15.5 2.25 15.5H13.25C14.75 15.5 16 14.2812 16 12.75V3.25C16 2.3125 15.1875 1.5 14.25 1.5ZM14.5 12.75C14.5 13.4688 13.9375 14 13.25 14H4.34375C4.4375 13.7812 4.5 13.5312 4.5 13.25V3.25C4.5 3.125 4.59375 3 4.75 3H14.25C14.375 3 14.5 3.125 14.5 3.25V12.75ZM8.25 9H6.25C5.8125 9 5.5 9.34375 5.5 9.75C5.5 10.1875 5.8125 10.5 6.25 10.5H8.25C8.65625 10.5 9 10.1875 9 9.75C9 9.34375 8.65625 9 8.25 9ZM12.75 9H10.75C10.3125 9 10 9.34375 10 9.75C10 10.1875 10.3125 10.5 10.75 10.5H12.75C13.1562 10.5 13.5 10.1875 13.5 9.75C13.5 9.34375 13.1562 9 12.75 9ZM8.25 11.5H6.25C5.8125 11.5 5.5 11.8438 5.5 12.25C5.5 12.6875 5.8125 13 6.25 13H8.25C8.65625 13 9 12.6875 9 12.25C9 11.8438 8.65625 11.5 8.25 11.5ZM12.75 11.5H10.75C10.3125 11.5 10 11.8438 10 12.25C10 12.6875 10.3125 13 10.75 13H12.75C13.1562 13 13.5 12.6875 13.5 12.25C13.5 11.8438 13.1562 11.5 12.75 11.5ZM12.5 4H6.5C5.9375 4 5.5 4.46875 5.5 5V7C5.5 7.5625 5.9375 8 6.5 8H12.5C13.0312 8 13.5 7.5625 13.5 7V5C13.5 4.46875 13.0312 4 12.5 4Z"
      fill="currentColor"
    />
  </svg>
);
