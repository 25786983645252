import { Icon } from "@chakra-ui/react";

export const ViralContentSVGIcon = (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 8.5C20 8.9375 19.6562 9.25 19.25 9.25H15.4688L13.6562 13.0938C13.5312 13.375 13.25 13.5312 12.9062 13.5C12.5938 13.4688 12.3438 13.25 12.25 12.9375L10.0625 4.5625L7.71875 15.9062C7.65625 16.25 7.34375 16.5 7 16.5C6.65625 16.5 6.34375 16.2812 6.25 15.9688L4.40625 9.25H0.71875C0.3125 9.25 0 8.9375 0 8.5C0 8.09375 0.3125 7.75 0.71875 7.75H5C5.3125 7.75 5.625 8 5.71875 8.3125L6.875 12.5625L9.25 1.125C9.3125 0.78125 9.625 0.53125 9.96875 0.5H10C10.3125 0.5 10.625 0.75 10.7188 1.0625L13.1875 10.5625L14.3125 8.1875C14.4375 7.9375 14.6875 7.75 15 7.75H19.25C19.6562 7.75 20 8.09375 20 8.5Z"
      fill="currentColor"
    />
  </svg>
);
