import { Icon } from "@chakra-ui/react";

export const EngageSVGIcon = (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 8.5C9.1875 8.5 11 6.71875 11 4.5C11 2.3125 9.1875 0.5 7 0.5C4.78125 0.5 3 2.3125 3 4.5C3 6.71875 4.78125 8.5 7 8.5ZM7 2C8.375 2 9.5 3.125 9.5 4.5C9.5 5.90625 8.375 7 7 7C5.59375 7 4.5 5.90625 4.5 4.5C4.5 3.125 5.59375 2 7 2ZM8.5625 10H5.40625C2.40625 10 0 12.4375 0 15.4375C0 16.0312 0.46875 16.5 1.0625 16.5H12.9062C13.5 16.5 14 16.0312 14 15.4375C14 12.4375 11.5625 10 8.5625 10ZM1.5 15C1.71875 13.0625 3.375 11.5 5.40625 11.5H8.5625C10.5938 11.5 12.2188 13.0625 12.4688 15H1.5ZM14.9688 10.5H12.6562C14.0938 11.6875 15 13.4688 15 15.4375C15 15.8438 14.875 16.1875 14.6875 16.5H19C19.5312 16.5 20 16.0625 20 15.5C20 12.75 17.75 10.5 14.9688 10.5ZM13.5 8.5C15.4062 8.5 17 6.9375 17 5C17 3.09375 15.4062 1.5 13.5 1.5C12.6875 1.5 11.9688 1.78125 11.4062 2.21875C11.75 2.90625 12 3.6875 12 4.5C12 5.625 11.625 6.65625 11 7.46875C11.625 8.125 12.5 8.5 13.5 8.5Z"
      fill="currentColor"
    />
  </svg>
);
