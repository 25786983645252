import {
  Box,
  Divider,
  Flex,
  IconButton,
  useColorModeValue,
  VStack,
  Button,
  Text,
  Icon,
  ComponentWithAs,
  IconProps,
} from "@chakra-ui/react";
import {
  AiOutlineContacts,
  AiOutlineSearch,
  AiOutlineTeam,
} from "react-icons/ai";
import { FiSend } from "react-icons/fi";
import { FaRegListAlt } from "react-icons/fa";
import { NavItem } from "./navItem";
import { AccordianNav } from "./accordianNav";
import { useTweetContext } from "context/tweetContext";
import { WriteAPostSVGIcon } from "./icons/WriteAPostSVGIcon";
import { MyQueueSVGIcon } from "./icons/MyQueueSVGIcon";
import { IconType } from "react-icons/lib";
import { KanbanIcon } from "./icons/KanbanIcon";
import { MyStatsSVGIcon } from "./icons/MyStatsSVGIcon";
import { GeneratorsAISVGIcon } from "./icons/GeneratorsAISVGIcon";
import { GeneratorsAIExpandedSVGIcon } from "./icons/GeneratorsAIExpandedSVGIcon";
import { ViralContentSVGIcon } from "./icons/ViralContentSVGIcon";
import { SearchModeSVGIcon } from "./icons/SearchModeSVGIcon";
import { IndustryNewsSVGIcon } from "./icons/IndustryNewsSVGIcon";
import { MySavedContentSVGIcon } from "./icons/MySavedContentSVGIcon";
import { ContactSVGIcon } from "./icons/ContactSVGIcon";
import { OutreachSVGIcon } from "./icons/OutreachSVGIcon";
import { EngageSVGIcon } from "./icons/EngageSVGIcon";
import { GrContactInfo } from "react-icons/gr";
import { MdOutlineContactPage } from "react-icons/md";
import { RiContactsLine } from "react-icons/ri";
import { variant } from "theme/names";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useSession } from "next-auth/react";
import { useWindowWidth } from "@react-hook/window-size";
import { useChatContext } from "context/chatContext";
import { CollabSVGIcon } from "./icons/CollabSVGIcon";
import { FilmImageIcon } from "./icons/FilmImageIcon";
import { MentionIcon } from "./icons/MentionIcon";
import { isFeatureEnabled, setInLocalStorage } from "utils/helpers";

interface Props {
  navSize: "small" | "large";
  changeNavSize: (val: "small" | "large") => void;
  setWithShadowFooter: Dispatch<SetStateAction<boolean>>;
  setMenuOpen?: (val: boolean) => void;
}

export function NavSectionTitle({
  title,
  navSize,
  compact = false,
  setMenuOpen,
  ...props
}: {
  title: string;
  navSize: "small" | "large";
  compact?: boolean;
  setMenuOpen?: (val: boolean) => void;
  [props: string]: any;
}) {
  return (
    <Box
      {...{
        mt: compact ? 1 : 6,
        mb: compact ? 1 : 2,
        ml: compact ? 1 : 2,
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "11px",
        lineHeight: "20px",
        letterSpacing: "1.5px",
        color: "#98A1AC",
        onClick: () => {
          if (navSize === "small" && setMenuOpen) {
            setMenuOpen(true);
            setInLocalStorage("navSize", "large");
          }
        },
        ...props,
      }}
    >
      {navSize === "large" && title}
      {navSize === "small" && <Box h={5} />}
    </Box>
  );
}

export function MainNavItems({
  navSize,
  changeNavSize,
  setWithShadowFooter,
  setMenuOpen,
}: Props) {
  const { data: session, status } = useSession() ?? {};
  const tweetContext: any = useTweetContext();
  const chatContext: any = useChatContext();
  const [expanded, setExpanded] = useState(false);
  const ref = useRef(null);
  const screenWidth = useWindowWidth();
  const isTooSmall = screenWidth < 800;
  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";

  const handleOpenWritePostMenu = (): void => {
    if (isTooSmall && chatContext?.isOpen) {
      chatContext?.onClose();
    }
    tweetContext.open();
  };

  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      if (ref.current?.scrollHeight && ref.current?.clientHeight) {
        // scrollHeight is the height of the content, including content that is not visible on the screen due to overflow.
        // clientHeight is the height of the content, not including content that is not visible on the screen due to overflow. It acts as the height of the container of the overflowed content.
        setWithShadowFooter(
          // @ts-ignore
          ref.current?.scrollHeight > ref.current?.clientHeight
        );
      }
    }, 200); // timeout for the accordion to expand and collapse
  }, [expanded, navSize]);

  return (
    <Box
      onClick={(e) => {
        if (e.target === e.currentTarget && setMenuOpen) {
          setMenuOpen(true);
          setInLocalStorage("navSize", "large");
        }
      }}
      ref={ref}
      w="100%"
      flexDirection="column"
      flex="1"
      overflow="hidden auto"
      className="noScrollBar"
      overscrollBehavior={"contain"}
      px={navSize === "small" ? "2" : "3"}
      pb={3}
    >
      <VStack spacing={"1"}>
        <Flex justifyContent="center" w="100%" mt="6" mb="4">
          {navSize === "small" ? (
            <IconButton
              variant={variant.Button.primary}
              aria-label="Write a tweet"
              icon={<WriteAPostSVGIcon />}
              w="40px"
              onClick={handleOpenWritePostMenu}
            />
          ) : (
            <Button
              variant={variant.Button.primary}
              leftIcon={<WriteAPostSVGIcon />}
              p={navSize == "large" ? "4" : "0"}
              mx={navSize == "large" ? "3" : "auto"}
              w="full"
              onClick={handleOpenWritePostMenu}
            >
              Write a post
            </Button>
          )}
        </Flex>
        <NavItem
          title={`My ${origin?.includes("app.taplio.com") ? "Queue" : "Posts"}`}
          href={`/queue${
            origin?.includes("app.taplio.com") ? "?tab=schedule" : ""
          }`}
          icon={MyQueueSVGIcon as unknown as IconType}
          navSize={navSize}
          matchPath="/queue"
        />
        <NavItem
          title="My Drafts"
          href="/drafts"
          icon={KanbanIcon as unknown as IconType}
          navSize={navSize}
        />
        <NavItem
          title="My Stats"
          href="/stats"
          icon={MyStatsSVGIcon as unknown as IconType}
          navSize={navSize}
        />
        <NavItem
          title={<Text as="span">Collaboration</Text>}
          href="/collaboration"
          // icon={
          //   <Icon as={AiOutlineTeam} />
          // }
          icon={CollabSVGIcon}
          navSize={navSize}
        />
      </VStack>

      <NavSectionTitle
        {...{ title: "CONTENT CREATION", navSize }}
        setMenuOpen={setMenuOpen}
      />

      <VStack spacing={"1"}>
        <AccordianNav
          icon={<GeneratorsAISVGIcon />}
          iconExpanded={GeneratorsAIExpandedSVGIcon as unknown as IconType}
          navSize={navSize}
          setMenuOpen={setMenuOpen}
          title="AI Generators"
          {...{ expanded, setExpanded }}
        >
          <VStack spacing={"1"}>
            <NavItem
              title="Generated For You"
              href="/suggested"
              navSize={navSize}
              iconStroke="0"
              accordion
            />
            <NavItem
              title="Post Generator"
              href="/post-generator"
              navSize={navSize}
              iconStroke="0"
              accordion
            />
            <NavItem
              title="Hook Generator"
              href="/hook"
              navSize={navSize}
              iconStroke="0"
              accordion
            />
            <NavItem
              title="Carousel Generator"
              href="/carousel-generator"
              navSize={navSize}
              iconStroke="0"
              accordion
            />
            <NavItem
              title="Repurpose Content"
              href="/repurpose-content"
              navSize={navSize}
              iconStroke="0"
              accordion
            />
          </VStack>
        </AccordianNav>
        <NavItem
          title="Viral content"
          href="/auto"
          icon={ViralContentSVGIcon as unknown as IconType}
          navSize={navSize}
        />
        <NavItem
          title="Search-mode"
          href="/search"
          icon={SearchModeSVGIcon as unknown as IconType}
          navSize={navSize}
        />
        <NavItem
          title="Industry News"
          href="/news"
          icon={IndustryNewsSVGIcon as unknown as IconType}
          navSize={navSize}
          iconStroke="0"
        />
        <NavItem
          title="Saved Posts"
          href="/collections?tab=collections"
          icon={MySavedContentSVGIcon as unknown as IconType}
          navSize={navSize}
          iconStroke="1"
        />
        <NavItem
          title="My assets"
          href="/medias"
          icon={FilmImageIcon}
          navSize={navSize}
        />
      </VStack>
      <NavSectionTitle
        {...{ title: "CRM", navSize }}
        setMenuOpen={setMenuOpen}
      />
      <VStack spacing={"1"}>
        <NavItem
          title="Database (new)"
          href="/database"
          icon={RiContactsLine}
          navSize={navSize}
          iconStroke="0"
        />
        <NavItem
          title="Contacts"
          href="/contacts"
          icon={ContactSVGIcon as unknown as IconType}
          navSize={navSize}
          iconStroke="0"
        />
        {isFeatureEnabled("outreach", session, {}, false, "") && (
          <NavItem
            title="Outreach"
            href="/dm"
            icon={OutreachSVGIcon as unknown as IconType}
            navSize={navSize}
          />
        )}
        <NavItem
          title="Engage"
          href="/engage"
          icon={EngageSVGIcon as unknown as IconType}
          navSize={navSize}
          iconStroke="0"
        />
        <NavItem
          title="Mentions"
          href="/mentions"
          icon={MentionIcon as unknown as IconType}
          navSize={navSize}
          iconStroke="0"
        />
      </VStack>
    </Box>
  );
}
