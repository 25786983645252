export const GeneratorsAISVGIcon = (props) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{...props}}
  >
    <path
      d="M7.0625 6.25C6.84375 5.78125 6.125 5.78125 5.90625 6.25L4.15625 10.25C4.03125 10.5625 4.15625 10.9375 4.46875 11.0625C4.8125 11.1875 5.15625 11.0625 5.3125 10.75L5.46875 10.375C5.46875 10.375 5.46875 10.375 5.5 10.375H7.5L7.65625 10.7812C7.75 11 8 11.125 8.25 11.125C8.3125 11.125 8.40625 11.125 8.5 11.0938C8.8125 10.9375 8.9375 10.5938 8.8125 10.25L7.0625 6.25ZM6.03125 9.125L6.5 8.0625L6.9375 9.125H6.03125ZM10.5 5.875C10.125 5.875 9.875 6.15625 9.875 6.5V10.5C9.875 10.875 10.125 11.125 10.5 11.125C10.8438 11.125 11.125 10.875 11.125 10.5V6.5C11.125 6.15625 10.8438 5.875 10.5 5.875ZM15.25 9.25C15.6562 9.25 16 8.9375 16 8.5C16 8.09375 15.6562 7.75 15.25 7.75H14V6.25H15.25C15.6562 6.25 16 5.9375 16 5.5C16 5.09375 15.6562 4.75 15.25 4.75H14V4.5C14 3.40625 13.0938 2.5 12 2.5H11.75V1.25C11.75 0.84375 11.4062 0.5 11 0.5C10.5625 0.5 10.25 0.84375 10.25 1.25V2.5H8.75V1.25C8.75 0.84375 8.40625 0.5 8 0.5C7.5625 0.5 7.25 0.84375 7.25 1.25V2.5H5.75V1.25C5.75 0.84375 5.40625 0.5 5 0.5C4.5625 0.5 4.25 0.84375 4.25 1.25V2.5H4C2.875 2.5 2 3.40625 2 4.5V4.75H0.75C0.3125 4.75 0 5.09375 0 5.5C0 5.9375 0.3125 6.25 0.75 6.25H2V7.75H0.75C0.3125 7.75 0 8.09375 0 8.5C0 8.9375 0.3125 9.25 0.75 9.25H2V10.75H0.75C0.3125 10.75 0 11.0938 0 11.5C0 11.9375 0.3125 12.25 0.75 12.25H2V12.5C2 13.625 2.875 14.5 4 14.5H4.25V15.75C4.25 16.1875 4.5625 16.5 5 16.5C5.40625 16.5 5.75 16.1875 5.75 15.75V14.5H7.25V15.75C7.25 16.1875 7.5625 16.5 8 16.5C8.40625 16.5 8.75 16.1875 8.75 15.75V14.5H10.25V15.75C10.25 16.1875 10.5625 16.5 11 16.5C11.4062 16.5 11.75 16.1875 11.75 15.75V14.5H12C13.0938 14.5 14 13.625 14 12.5V12.25H15.25C15.6562 12.25 16 11.9375 16 11.5C16 11.0938 15.6562 10.75 15.25 10.75H14V9.25H15.25ZM12.5 12.5C12.5 12.7812 12.25 13 12 13H4C3.71875 13 3.5 12.7812 3.5 12.5V4.5C3.5 4.25 3.71875 4 4 4H12C12.25 4 12.5 4.25 12.5 4.5V12.5Z"
      fill="currentColor"
      // fill="#566F8F"
    />
  </svg>
);
