import { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Icon,
  useColorModeValue,
  Text,
  Box,
  AccordionIcon,
  useColorMode,
} from "@chakra-ui/react";
import {
  BsCaretDown,
  BsChevronDown,
  BsChevronRight,
  BsFillCaretDownFill,
  BsFillCaretUpFill,
} from "react-icons/bs";
import { IconType } from "react-icons/lib";
import { setInLocalStorage } from "utils/helpers";
import { motion } from "framer-motion";
import { color, textStyle } from "theme/names";
import { useRouter } from "next/router";

interface Props {
  navSize: "large" | "small";
  icon: any;
  iconExpanded: any;
  title: string;
  children: React.ReactNode;
  isInGrowPlan?: boolean;
  expanded?: boolean;
  setExpanded?: (val: boolean) => void;
  setMenuOpen?: (val: boolean) => void;
}

export function AccordianNav({
  navSize,
  icon,
  iconExpanded = <></>,
  title,
  children,
  expanded,
  setExpanded,
  setMenuOpen,
}: Props) {
  const [expandedIndex, setExpandedIndex] = useState<any>([]);
  const key = "acc_" + title.toLowerCase().replace(" ", "_");

  useEffect(() => {
    const savedState = localStorage.getItem(key);
    if (savedState) {
      setExpandedIndex(JSON.parse(savedState));
    }
  }, []);

  // get current route
  const { pathname } = useRouter();
  // console.log("pathName", pathname);
  // route is /suggested
  const isAiGeneratedPath = pathname.includes("suggested");
  // console.log({isAiGeneratedRoute});

  // hoverState
  const [buttonHoverState, setButtonHoverState] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Accordion
      w="100%"
      mx="4"
      allowMultiple
      index={navSize !== "small" ? expandedIndex : []}
      onChange={(ei) => {
        setExpandedIndex(ei);
        setInLocalStorage(key, JSON.stringify(ei));
      }}
    >
      <AccordionItem border="0" display="flex" justifyContent="center">
        {({ isExpanded }) => (
          <Box w="100%">
            <AccordionButton
              {...{
                // background with a gradient
                _before: {
                  content: '""', // required for visibility
                  bgGradient: isExpanded
                    ? "linear(to-br, #EC618116 0%, #316BFF16 100%)"
                    : "",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  borderRadius: "9px",
                },
                // regular text color
                color: isExpanded ? undefined : "text.lightMode.light",
                // hover: regular when !expanded, darker gradient with filter when expanded
                _hover: {
                  filter: isExpanded ? "brightness(1.25)" : undefined,
                  color: isExpanded ? undefined : "text.lightMode.standard", // regular text color
                  bg: isExpanded ? undefined : color["primary.lightMode.100"],
                  px: "12px",
                  py: "6px",
                },
                // when expanded we want to color text with bgGradient + bgClip, when not expanded we want to use color
                bgGradient: isExpanded
                  ? "linear(to-br, #EC6181 0%, #316BFF 100%)"
                  : undefined,
                bgClip: isExpanded ? "text" : undefined,
                // dark mode
                _dark: {
                  // background with a gradient
                  _before: {
                    content: '""', // required for visibility
                    bgGradient: isExpanded
                      ? "linear(to-br, #EC618155 0%, #316BFF55 100%)"
                      : "",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    borderRadius: "9px",
                  },
                  bgGradient: undefined,
                  bgClip: undefined,
                  // text colors:
                  // gray.200 normal, gray.400 hover, gray.50 active
                  color: isExpanded
                    ? color["text.darkMode.standard"]
                    : color["text.darkMode.light"],
                  //hover
                  _hover: {
                    filter: isExpanded ? "brightness(1.15)" : undefined,
                    color: isExpanded
                      ? undefined
                      : color["text.darkMode.standard"], // regular text color
                    bg: isExpanded ? undefined : color["primary.darkMode.100"],
                  },
                },
              }}
              position="relative"
              px={navSize === "large" ? "3" : "0"}
              py="2"
              w={navSize == "large" ? "100%" : "auto"}
              borderRadius="8"
              h="40px"
              display="flex"
              alignItems="center"
              fontWeight={isExpanded ? "600" : "500"}
              fontSize="sm"
              mx="auto"
              justifyContent={navSize === "small" ? "center" : "left"}
              _focus={{ boxShadow: "none" }}
              // hover state
              onMouseEnter={() => setButtonHoverState(true)}
              onMouseLeave={() => setButtonHoverState(false)}
              onClick={() => {
                if (navSize === "small" && setMenuOpen) {
                  setMenuOpen(true);
                  setInLocalStorage("navSize", "large");
                  setExpanded && setExpanded(true);
                } else {
                  setExpanded && setExpanded(!expanded);
                }
              }}
            >
              {/* AI icon */}
              <Icon
                viewBox="0 0 16 16"
                w="4"
                h="4"
                // ml="0.5"
                style={{
                  filter: (() => {
                    // dark mode expanded accordion
                    if (colorMode === "dark" && isExpanded && !buttonHoverState)
                      return "brightness(1.8)"; // dark mode: make icon very bright
                    // dark mode expanded accordion on hover
                    if (colorMode === "dark" && isExpanded && buttonHoverState)
                      return "brightness(1.75)"; // dark mode: less bright when hover
                    return undefined;
                  })(),
                }}
              >
                {isExpanded || isAiGeneratedPath ? iconExpanded : icon}
              </Icon>
              <Text
                // textStyle={textStyle["body.medium.light"]}
                ml={navSize === "large" ? "3" : "0"}
                display={navSize == "small" ? "none" : "flex"}
                whiteSpace="nowrap"
                fontSize="sm"
                flex="1"
                zIndex={1}
                {...{
                  // when expanded we want to color text with bgGradient + bgClip, when not expanded we want to use color
                  bgGradient: isExpanded
                    ? "linear(to-br, #EC6181 0%, #316BFF 100%)"
                    : undefined,
                  bgClip: isExpanded ? "text" : undefined,
                  _dark: {
                    // color: isExpanded ? color["text.darkMode.standard"] : color["text.darkMode.light"],
                    // _hover: {
                    //   color: isExpanded ? color["text.darkMode.standard"] : color["text.darkMode.standard"],
                    // },
                    bgGradient: isExpanded
                      ? "linear(to-br, #EC6181 0%, #316BFF 70%)"
                      : undefined,
                    bgClip: isExpanded ? "text" : undefined,
                  },
                }}
                style={{
                  filter: (() => {
                    // dark mode expanded accordion
                    if (colorMode === "dark" && isExpanded && !buttonHoverState)
                      return "brightness(1.75)"; // dark mode: make icon very bright
                    // dark mode expanded accordion on hover
                    if (colorMode === "dark" && isExpanded && buttonHoverState)
                      return "brightness(1.8)"; // dark mode: less bright when hover
                    return undefined;
                  })(),
                }}
              >
                {title}
              </Text>
              {/* rotating caret icon */}
              <motion.div
                animate={{
                  rotate: isExpanded ? 180 : 0,
                }}
                transition={{ type: "spring", duration: 0.5, bounce: 0 }}
              >
                <Icon
                  as={BsFillCaretDownFill}
                  {...{
                    color: isExpanded
                      ? "ai.400" // isExpanded
                      : buttonHoverState
                      ? "text.lightMode.standard" // !isExpanded && buttonHoverState
                      : "text.lightMode.light", // !isExpanded && !buttonHoverState
                    // dark mode
                    _dark: {
                      // color: isExpanded ? "gray.50" : "gray.200",
                      color: isExpanded
                        ? buttonHoverState
                          ? "ai.200" // isExpanded && buttonHoverState
                          : "ai.300" // isExpanded && !buttonHoverState
                        : buttonHoverState
                        ? "text.darkMode.standard" // !isExpanded && buttonHoverState
                        : "text.darkMode.light", // !isExpanded && !buttonHoverState
                    },
                  }}
                  display={navSize == "small" ? "none" : "flex"}
                />
              </motion.div>
            </AccordionButton>
            <AccordionPanel
              position={"relative"}
              p="0"
              {...{
                // blue line
                _before: {
                  content: '""', // required for visibility
                  background: "primary.lightMode.100",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "2px",
                  height: "100%",
                  ml: "28px",
                },
                _dark: {
                  _before: {
                    content: '""', // required for visibility
                    background: color["border.darkMode.light"],
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "2px",
                    height: "100%",
                    ml: "28px",
                  },
                },
              }}
            >
              {children}
            </AccordionPanel>
          </Box>
        )}
      </AccordionItem>
    </Accordion>
  );
}
