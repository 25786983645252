import { Icon } from "@chakra-ui/react";

export const ContactSVGIcon = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 9.5H6.5C5.09375 9.5 4 10.625 4 12C4 12.2812 4.21875 12.5 4.5 12.5H10.5C10.75 12.5 11 12.2812 11 12C11 10.625 9.875 9.5 8.5 9.5ZM7.5 8.5C8.59375 8.5 9.5 7.625 9.5 6.5C9.5 5.40625 8.59375 4.5 7.5 4.5C6.375 4.5 5.5 5.40625 5.5 6.5C5.5 7.625 6.375 8.5 7.5 8.5ZM15.5 10.5H15V13.5H15.5C15.75 13.5 16 13.2812 16 13V11C16 10.75 15.75 10.5 15.5 10.5ZM15.5 2.5H15V5.5H15.5C15.75 5.5 16 5.28125 16 5V3C16 2.75 15.75 2.5 15.5 2.5ZM15.5 6.5H15V9.5H15.5C15.75 9.5 16 9.28125 16 9V7C16 6.75 15.75 6.5 15.5 6.5ZM12 0.5H3C1.875 0.5 1 1.40625 1 2.5V14.5C1 15.625 1.875 16.5 3 16.5H12C13.0938 16.5 14 15.625 14 14.5V2.5C14 1.40625 13.0938 0.5 12 0.5ZM12.5 14.5C12.5 14.7812 12.25 15 12 15H3C2.71875 15 2.5 14.7812 2.5 14.5V2.5C2.5 2.25 2.71875 2 3 2H12C12.25 2 12.5 2.25 12.5 2.5V14.5Z"
      fill="currentColor"
    />
  </svg>
);
