import { useEffect, useRef } from "react";
import { MyWindow } from "../types/mouseflowWindow";
declare var window: MyWindow;

export function useMouseflow(callback = () => { }, delay = 100) {
  const savedCallback = useRef<any>();

  useEffect(() => {
    window._mfq = window._mfq || [];
    (function () {
      var mf = document.createElement("script");
      mf.type = "text/javascript";
      mf.defer = true;
      mf.src = "//cdn.mouseflow.com/projects/e1d2c821-5584-46bf-887d-6290af76285a.js";
      document.getElementsByTagName("head")[0].appendChild(mf);
    })();
  }, []);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}