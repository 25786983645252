import { Box, Stack, Text, Link, Button, IconButton } from "@chakra-ui/react";
import React from "react";
import { Copyright } from "./copyright";
import { Logo } from "components/logo";
import { SocialMediaLinks } from "./sm-links";
import { TweetContext } from "../../context/tweetContext";
import { FiTwitter, FiFeather } from 'react-icons/fi';
import { Icon } from '@chakra-ui/icons'
import { useMouseflow } from "utils/useMouseflow";

export const Footer = ({ disableScheduler = false }) => {

  const tweetContext = React.useContext(TweetContext);
  useMouseflow();

  return (
    <>
      {/* <Box
        as="footer"
        role="contentinfo"
        mx="auto"
        // maxW="7xl"
        py="5"
        px={{ base: "4", md: "8" }}
        position="absolute"
        bottom="0"
        width="100%"
      >
        <Stack display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end">
          <Text textStyle={textStyle["body.medium.light"]} fontSize="sm" as="i"color="gray.400">
            Created by <Link href="https://ponyexpress.studio/" target="_blank">Pony Express</Link>
          </Text>
        </Stack>
      </Box> */}
      {/* {
        !disableScheduler && (
          <Box
            position="fixed"
            bottom="20px"
            right="20px"
          >
            <IconButton
              aria-label="display tweet composer"
              borderRadius="200px"
              colorScheme="twitter"
              size="lg"
              fontSize="30px"
              // pt={6}
              // pb={6}
              onClick={() => {
                //@ts-ignore
                tweetContext.setSelectedTweet(null);
                //@ts-ignore
                tweetContext.open(true);
              }}
              icon={<FiFeather />}
            />
          </Box>
        )
      } */}
    </>
  );
};
