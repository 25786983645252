import { useContext } from "react";
import {
  Flex,
  Link,
  Icon,
  Text,
  useColorModeValue,
  FlexProps,
  Box,
  useColorMode,
  Tooltip,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { IconType } from "react-icons/lib";
import { useRouter } from "next/router";
import { color } from "theme/names";
import { TweetContext } from "../../context/tweetContext";

interface Props {
  title: string | React.ReactElement;
  href?: string;
  navSize: "small" | "large";
  icon?: IconType | typeof Icon | React.ReactElement;
  children?: React.ReactElement;
  iconStroke?: string;
  mainContainerProps?: FlexProps;
  leastCommonContainerProps?: FlexProps;
  matchPath?: string;
  accordion?: boolean;
}

export function NavItem({
  title,
  href = "",
  icon,
  navSize,
  mainContainerProps = {},
  leastCommonContainerProps = {},
  matchPath = "",
  accordion = false,
}: Props) {
  const router = useRouter();
  const isActive = href && router.asPath.includes(matchPath || href);
  const marginLeftWidthInPixels = accordion ? `28px` : "0px";
  const { colorMode } = useColorMode();

  let buttonContainerProps = {
    background:
      !accordion && navSize === "large" && isActive
        ? "primary.lightMode.100"
        : "transparent",
    color: (() => {
      if (accordion && isActive) return undefined; // if active and accordion, then we want the text color to come from the gradient
      if (accordion && !isActive) return "text.lightMode.light";
      if (!accordion && isActive) return "text.lightMode.standard";
      if (!accordion && !isActive) return "text.lightMode.light";
      return undefined;
    })(),
    // text gradient is bgGradient and bgClip https://chakra-ui.com/docs/styled-system/gradient#text-gradient-api
    bgGradient: (() => {
      if (accordion && isActive)
        return "linear(to-br, #EC6181 0%, #316BFF 100%)"; // if active and accordion, then we want the gradient
      return undefined; // if not active and accordion, then we want the text color to come from the color prop
    })(),
    bgClip: (() => {
      if (accordion && isActive) return "text"; // if active and accordion, then we want the gradient
      return undefined; // if not active and accordion, then we want the text color to come from the color prop
    })(),
    _hover: {
      color: isActive ? undefined : "text.lightMode.standard", // on hover text goes from text.light to text.standard
      filter:
        accordion && isActive && colorMode === "light"
          ? "brightness(1.25)"
          : undefined,
      bg:
        accordion && isActive && colorMode === "light"
          ? undefined
          : color["primary.lightMode.100"],
      px: "12px",
      py: "6px",
    },
    _dark: {
      // dark mode colors
      backgroundColor:
        !accordion && navSize === "large" && isActive
          ? color.background.darkMode.light
          : undefined,
      color: (() => {
        if (accordion && isActive) return undefined; // if active and accordion, then we want the text color to come from the gradient
        if (accordion && !isActive) return "text.darkMode.light";
        if (!accordion && isActive) return "text.darkMode.standard";
        if (!accordion && !isActive) return "text.darkMode.light";
        return undefined;
      })(),
      _hover: {
        color: isActive ? undefined : "text.darkMode.standard", // on hover text goes from gray.200 to gray.400 (in dark mode)
        filter: isActive ? "brightness(1.15)" : undefined, // on hover text with gradient goes a little darker
        bg: color["primary.darkMode.100"],
      },
    },
    fontWeight: (() => {
      if (isActive) return "600";
      return "500";
    })(),
    h: "36px",
    py: "2",
    pl: accordion
      ? navSize === "small"
        ? "0"
        : "4"
      : navSize === "small"
      ? "0"
      : "3",
    ml: accordion ? marginLeftWidthInPixels : "0",
    w: navSize == "large" ? "100%" : "auto",
    borderRadius: "4px",
    // transition: "all 0.5s",
  };

  return (
    <Flex
      w="100%"
      alignItems={navSize == "large" ? "flex-start" : "center"}
      flexDir="column"
      {...mainContainerProps}
    >
      <Tooltip
        label={typeof title === "string" ? title : ""}
        aria-label={`Tooltip for ${title}`}
        placement="right"
        isDisabled={navSize === "large"}
      >
        {!!href ? (
          <Link as={NextLink} href={href} {...buttonContainerProps}>
            {navItemButton(
              title,
              isActive,
              icon,
              navSize,
              accordion,
              leastCommonContainerProps
            )}
          </Link>
        ) : (
          <Box {...buttonContainerProps} cursor="pointer">
            {navItemButton(
              title,
              isActive,
              icon,
              navSize,
              accordion,
              leastCommonContainerProps
            )}
          </Box>
        )}
      </Tooltip>
    </Flex>
  );
}

const navItemButton = function (
  title,
  isActive,
  icon,
  navSize,
  accordion,
  leastCommonContainerProps = {}
) {
  // colormode
  const { colorMode } = useColorMode();
  const tweetContext: any = useContext(TweetContext);

  const handleNavItemClick = () => {
    const {
      isFullModeOpen,
      setIsIncognitoMode,
      setIsFullModeOpen,
      setTextState,
      refComposer,
    } = tweetContext;
    if (isFullModeOpen) {
      setTextState(refComposer?.current?.textState());
      setIsFullModeOpen(false);
      setIsIncognitoMode(false);
    }
  };

  const iconColor = useColorModeValue(
    isActive ? "primary.lightMode.default" : undefined,
    isActive ? "primary.lightMode.200" : undefined
  );

  return (
    <Flex
      alignItems="center"
      minH="24px"
      onClick={handleNavItemClick}
      {...leastCommonContainerProps}
    >
      {icon &&
        (typeof icon === "function" ? (
          <Icon
            viewBox="0 0 20 17"
            color={iconColor} // icon is blue when active
            as={icon}
          />
        ) : (
          <Icon
            viewBox="0 0 20 17"
            color={iconColor} // icon is blue when active
          >
            {icon}
          </Icon>
        ))}
      <Text
        ml={icon ? "3" : "0"}
        fontSize="sm"
        whiteSpace="nowrap"
        display={navSize == "small" ? "none" : "flex"}
        {...{
          _dark: {
            // text gradient needs to be applied to text
            bgGradient: (() => {
              if (accordion && isActive)
                return "linear(to-br, #EC6181 0%, #316BFF 100%)"; // if active and accordion, then we want the gradient
              return undefined; // if not active and accordion, then we want the text color to come from the color prop
            })(),
            bgClip: (() => {
              if (accordion && isActive) return "text"; // if active and accordion, then we want the gradient
              return undefined; // if not active and accordion, then we want the text color to come from the color prop
            })(),
          },
        }}
        style={{
          filter:
            accordion && isActive && colorMode === "dark"
              ? "brightness(1.75)"
              : undefined,
        }}
      >
        {title}
      </Text>
    </Flex>
  );
};
