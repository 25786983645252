import { Icon } from "@chakra-ui/react";

export const OutreachSVGIcon = (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6562 0.65625C16.4375 0.5 16.125 0.46875 15.875 0.625L1.375 8.875C1.125 9 0.96875 9.28125 1 9.5625C1 9.84375 1.1875 10.0938 1.4375 10.2188L6.25 12.2188V15.75C6.25 16.0312 6.375 16.2812 6.625 16.4375C6.75 16.4688 6.875 16.5 6.96875 16.5C7.09375 16.5 7.25 16.4688 7.375 16.4062L10.8438 14.1562L13.9062 15.4688C14 15.5 14.0938 15.5312 14.2188 15.5312C14.3438 15.5312 14.4688 15.4688 14.5625 15.4062C14.7812 15.3125 14.9062 15.0938 14.9375 14.875L16.9375 1.375C17.0312 1.09375 16.9062 0.8125 16.6562 0.65625ZM12.5312 4.25L6.6875 10.7812L3.4375 9.40625L12.5312 4.25ZM7.71875 14.375V12.8438L9.15625 13.4688L7.71875 14.375ZM13.625 13.6875L8.125 11.375L15.125 3.59375L13.625 13.6875Z"
      fill="currentColor"
    />
  </svg>
);
