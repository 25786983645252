import {
  Box,
  Heading,
  HTMLChakraProps,
  Stack,
  useMediaQuery,
  useColorMode,
  Tag,
  Grid,
} from "@chakra-ui/react";
import * as React from "react";

export const SvgLogoIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="13" fill="#38A1D6" />
    <circle cx="16" cy="16" r="15.5" stroke="#3D41A6" />
    <circle cx="16" cy="16" r="8" fill="#3D41A6" />
  </svg>
);

export const SvgLogoTextTaplio = ({ white = false, h="31px" }) => (
  <svg
    width="90"
    height={h}
    viewBox="0 0 90 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3117 1.91249C18.3117 1.0995 17.6526 0.440438 16.8396 0.440438H1.47205C0.659062 0.440438 0 1.0995 0 1.91249V1.91249C0 2.72548 0.65906 3.38454 1.47205 3.38454H7.6029V21.5344C7.6029 22.3921 8.29817 23.0874 9.15583 23.0874V23.0874C10.0135 23.0874 10.7088 22.3921 10.7088 21.5344V3.38454H16.8396C17.6526 3.38454 18.3117 2.72548 18.3117 1.91249V1.91249Z"
      fill={white ? "#ffffff" : "#213856"}
    />
    <path
      d="M32.027 7.10511C31.2965 7.10511 30.7016 7.69196 30.6916 8.42237L30.6772 9.46686C29.4155 7.78452 27.2155 6.74923 24.5949 6.74923C19.9038 6.74923 16.6038 10.1786 16.6038 15.0962C16.6038 20.0139 19.9038 23.4433 24.5949 23.4433C27.2155 23.4433 29.4155 22.408 30.6772 20.7256L30.6916 21.7701C30.7016 22.5005 31.2965 23.0874 32.027 23.0874V23.0874C32.7646 23.0874 33.3625 22.4894 33.3625 21.7518V8.44066C33.3625 7.70306 32.7646 7.10511 32.027 7.10511V7.10511ZM24.9832 20.6609C21.8449 20.6609 19.6126 18.3639 19.6126 15.0962C19.6126 11.8286 21.8449 9.53157 24.9832 9.53157C28.1537 9.53157 30.3537 11.8286 30.3537 15.0962C30.3537 18.3639 28.1537 20.6609 24.9832 20.6609Z"
      fill={white ? "#ffffff" : "#213856"}
    />
    <path
      d="M46.8175 6.74923C44.1969 6.74923 41.9969 7.78452 40.7675 9.46686L40.7534 8.43843C40.7433 7.69912 40.1411 7.10511 39.4017 7.10511V7.10511C38.6551 7.10511 38.0499 7.71035 38.0499 8.45694V29.0888C38.0499 29.9197 38.7234 30.5932 39.5543 30.5932V30.5932C40.3851 30.5932 41.0587 29.9197 41.0587 29.0888V21.0815C42.3204 22.5697 44.391 23.4433 46.8175 23.4433C51.541 23.4433 54.8086 20.0139 54.8086 15.0962C54.8086 10.1786 51.541 6.74923 46.8175 6.74923ZM46.4293 20.6609C43.291 20.6609 41.0587 18.3639 41.0587 15.0962C41.0587 11.8286 43.291 9.53157 46.4293 9.53157C49.5998 9.53157 51.7998 11.8286 51.7998 15.0962C51.7998 18.3639 49.5998 20.6609 46.4293 20.6609Z"
      fill={white ? "#ffffff" : "#213856"}
    />
    <path
      d="M58.4283 21.583C58.4283 22.4138 59.1019 23.0874 59.9327 23.0874V23.0874C60.7636 23.0874 61.4371 22.4138 61.4371 21.583V1.94484C61.4371 1.11398 60.7636 0.440438 59.9327 0.440438V0.440438C59.1019 0.440438 58.4283 1.11398 58.4283 1.94484V21.583Z"
      fill={white ? "#ffffff" : "#213856"}
    />
    <path
      d="M67.6264 4.35512C68.8234 4.35512 69.6646 3.51395 69.6646 2.3816C69.6646 1.2169 68.8234 0.375732 67.6264 0.375732C66.4617 0.375732 65.5882 1.2169 65.5882 2.3816C65.5882 3.51395 66.4617 4.35512 67.6264 4.35512ZM66.1058 21.583C66.1058 22.4138 66.7793 23.0874 67.6102 23.0874V23.0874C68.4411 23.0874 69.1146 22.4138 69.1146 21.583V8.60951C69.1146 7.77865 68.4411 7.10511 67.6102 7.10511V7.10511C66.7793 7.10511 66.1058 7.77865 66.1058 8.60951V21.583Z"
      fill={white ? "#ffffff" : "#213856"}
    />
    <path
      d="M81.1597 23.4433C86.2391 23.4433 89.5714 19.8521 89.5714 15.0962C89.5714 10.3404 86.2391 6.74923 81.1597 6.74923C76.048 6.74923 72.7156 10.3404 72.7156 15.0962C72.7156 19.8521 76.048 23.4433 81.1597 23.4433ZM81.1597 20.6609C77.8597 20.6609 75.7244 18.1374 75.7244 15.0962C75.7244 12.0551 77.8597 9.53157 81.1597 9.53157C84.4273 9.53157 86.5626 12.0551 86.5626 15.0962C86.5626 18.1374 84.4273 20.6609 81.1597 20.6609Z"
      fill={white ? "#ffffff" : "#213856"}
    />
  </svg>
);

type ExtendedProps = {
  preserveText?: boolean;
  hideLogo?: boolean;
  isOnRight?: boolean;
  overrideSmallerThan600?: boolean;
  withIcon?: boolean;
  white?: boolean;
  textOnly?: boolean;
  h?: string
  ml?: string
};
type Props = HTMLChakraProps<"svg"> & ExtendedProps;

export const Logo = (props: Props) => {
  const [isSmallerThan600px] = useMediaQuery("(max-width: 600px)");
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Stack
      isInline
      // alignItems="end"
      // justifyContent="start"
    >
      {/* {props.preserveText ? (
        <SvgLogoTextTaplio dark={colorMode === "dark"} />
      ) : (
        !isSmallerThan600px && <SvgLogoTextTaplio />
      )} */}
      {/* <Box>
        <Tag
          variant="outline"
          mb="5"
          size="sm"
        >
          beta
        </Tag>
      </Box> */}
      <Grid gridTemplateColumns={"auto auto"} gap="2" h={props?.h} maxH={props.h}>
        {!props.textOnly && <SvgLogoIcon />}
        <Box mt={props.textOnly ? 0 : 1} h="full" maxH="full" ml={props?.ml}>
          <SvgLogoTextTaplio white={colorMode === "dark" || props.white} h={props?.h} />
        </Box>
      </Grid>
    </Stack>
  );
};
