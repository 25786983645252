export const WriteAPostSVGIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.3125 10.625L11.8125 1.125C12.5938 0.34375 13.875 0.34375 14.6562 1.125L15.875 2.34375C15.9688 2.4375 16.0625 2.5625 16.125 2.65625C16.6562 3.4375 16.5625 4.5 15.875 5.1875L6.375 14.6875C6.34375 14.7188 6.28125 14.75 6.25 14.8125C5.9375 15.0625 5.59375 15.25 5.21875 15.375L1.4375 16.4688C1.1875 16.5625 0.90625 16.5 0.71875 16.2812C0.5 16.0938 0.4375 15.8125 0.5 15.5625L1.625 11.7812C1.75 11.3438 2 10.9375 2.3125 10.625ZM3.0625 12.2188L2.34375 14.6562L4.78125 13.9375C4.96875 13.875 5.15625 13.7812 5.3125 13.625L12.4688 6.46875L10.5 4.53125L3.375 11.6875C3.34375 11.6875 3.34375 11.7188 3.3125 11.75C3.1875 11.875 3.125 12.0312 3.0625 12.2188Z"
      fill="white"
    />
  </svg>
);
