import { Icon } from "@chakra-ui/react";

export const MySavedContentSVGIcon = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 0.5H3.5C2.65625 0.5 2 1.1875 2 2V15.5C2 16.2812 2.8125 16.75 3.5 16.375L8 13.75L12.4688 16.375C13.1562 16.75 14 16.2812 14 15.5V2C14 1.1875 13.3125 0.5 12.5 0.5ZM12.5 14.625L8 12L3.5 14.625V2.1875C3.5 2.09375 3.5625 2 3.65625 2H12.2812C12.4062 2 12.5 2.09375 12.5 2.1875V14.625Z"
      fill="currentColor"
    />
  </svg>
);
